<template>
        <v-container fluid>
                <v-row justify="center" class="mt-0 mb-0 pt-0 pb-0">
                    <v-col cols="2" class="d-flex justify-center align-center mx-2">
                        <ejs-dropdownlist id="games" :dataSource="selection_Data" placeholder="Vizualizar por:"
                            width="250"></ejs-dropdownlist>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center mx-2">
                        <ejs-dropdownlist id="games" :dataSource="view_Data" placeholder="Agrupar por:"
                            width="250"></ejs-dropdownlist>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center mx-2">
                        <input class="e-input" type="number" placeholder="Meta" style="width: 250px" />
                    </v-col>
                </v-row>

                <ejs-chart style="display: block" :theme="theme" align="center" id="chartcontainer" :title="title"
                    :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis" :axes="axes" :chartArea="chartArea"
                    :width="width" :legendSettings="legendSettings" :tooltip="tooltip"
                    @tooltipRender="customizeTooltip">
                    <e-series-collection>
                        <e-series :dataSource="seriesData" type="Column" xName="Period" yName="fabrica" name="Fábrica"
                            width="2" opacity="1" fill="#121935">
                        </e-series>
                        <e-series :dataSource="seriesData" type="Line" xName="Period" yName="cnc_maquina" name="CNC"
                            width="2" opacity="1" :marker="marker">
                        </e-series>
                        <e-series :dataSource="seriesData" type="Line" xName="Period" yName="torno_maquina" name="TORNO"
                            width="2" opacity="1" :marker="marker">
                        </e-series>

                        <e-series :dataSource="seriesData" type="Line" xName="Period" yName="meta" name="META" width="2"
                            opacity="3" :marker="marker">
                        </e-series>
                    </e-series-collection>
                </ejs-chart>
        </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { Browser } from "@syncfusion/ej2-base";
import {
    ChartComponent,
    SeriesDirective,
    SeriesCollectionDirective,
    LineSeries,
    StackingColumnSeries,
    Tooltip,
    ColumnSeries,
    Category,
    Legend,
    Highlight,
    AnnotationsDirective,
    AnnotationDirective,
} from "@syncfusion/ej2-vue-charts";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "multiple_charts_desempenho_da_empresa",
    components: {
        "ejs-chart": ChartComponent,
        "ejs-dropdownlist": DropDownListComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
        "e-annotations": AnnotationsDirective,
        "e-annotation": AnnotationDirective,
    },
    data: function () {
        return {
            theme: theme,
            selection_Data: ["Dia", "Semana", "Mês", "Ano"],
            view_Data: ["Turno", "Máquinas", "Grupos"],
            fields: { text: "name", value: "value" },

            seriesData: [
                { Period: "2005", fabrica: 20, cnc_maquina: 1.2, torno_maquina: 8, meta: 75 },
                { Period: "2006", fabrica: 19, cnc_maquina: 2.3, torno_maquina: 10, meta: 75 },
                { Period: "2007", fabrica: 5, cnc_maquina: 2, torno_maquina: 12, meta: 75 },
                { Period: "2008", fabrica: 30, cnc_maquina: 3, torno_maquina: 15, meta: 75 },
                { Period: "2009", fabrica: 55, cnc_maquina: 7, torno_maquina: 9, meta: 75 },
                { Period: "2010", fabrica: 6, cnc_maquina: 1.8, torno_maquina: 5, meta: 75 },
                { Period: "2011", fabrica: 35, cnc_maquina: 2, torno_maquina: 10, meta: 75 },
                { Period: "2012", fabrica: 75, cnc_maquina: 4, torno_maquina: 15, meta: 75 },
                { Period: "2013", fabrica: 11, cnc_maquina: 9, torno_maquina: 19, meta: 75 },
                { Period: "2014", fabrica: 2, cnc_maquina: 4, torno_maquina: 17, meta: 75 },
                { Period: "2015", fabrica: 5, cnc_maquina: 3, torno_maquina: 14, meta: 75 },
            ],
            porcentagens: [50, 60, 70, 80], // Array com as porcentagens para o tooltip
            metas: [60, 65, 75, 85], // Array com as metas para o tooltip

            primaryXAxis: {
                labelIntersectAction: "Rotate45",
                valueType: "Category",
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
            },

            primaryYAxis: {
                minimum: 0,
                maximum: 100,
                interval: 5,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 },
                labelFormat: "{value}%",
            },
            chartArea: {
                border: {
                    width: 0,
                },
            },
            marker: {
                visible: true,
                width: 7,
                height: 7,
                circleMarker: { visible: true, width: 7, height: 7, isFilled: true },
                triangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
                diamondMarker: { visible: true, width: 7, height: 7, isFilled: true },
                rectangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
            },

            width: Browser.isDevice ? "100%" : "75%",
            legendSettings: {
                visible: true,
                enableHighlight: true,
            },
            tooltip: {
                enable: true,
                format: "Disponibilidade: ${point.y}%<br>Meta: ${point.y}%", // Exemplo de formato dinâmico
            },

            title: "Desemprenho da Empresa",
        };
    },

    provide: {
        chart: [
            StackingColumnSeries,
            LineSeries,
            Category,
            ColumnSeries,
            Legend,
            Tooltip,
            Highlight,
        ],
    },
    methods: {
        customizeTooltip(args) {
            console.log(args);
            // Definindo o conteúdo do tooltip dinamicamente

            if (args.headerText != "META") {
                args.text = `Disponibilidade: ${args.data.pointY}%`;
            } else {
                args.text = `Meta: ${args.data.pointY}%`;
            }
        },
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
};
</script>
