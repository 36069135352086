<template>
    <v-dialog v-model="appApontamento.showModal" transition="dialog-bottom-transition" fullscreen>
        <v-card height="120">
            <v-toolbar color="primary">
                <v-btn @click="appApontamento.showModal = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title style="font-size: 13px;font-weight: bold;">{{ appApontamento.titulo_modal
                    }}</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row class="d-flex align-center pe-2">
                    <v-col cols="12">
                        <v-card color="#EEEEEE">
                            <v-toolbar :color="colorStatusApontamento" class="toolbar-realtime" height="40"
                                id="v-toolbar_production">
                                <v-row justify="space-between" class="pl-0 pr-0">
                                    <!-- -->
                                </v-row>
                            </v-toolbar>

                            <v-row aligin="center">
                                <v-col cols="6">
                                    <div class="buttons mt-2 m-2">
                                        <v-row justify="center" class="mt-2">
                                            <small style="font-weight: bold;font-size: 20px;color:#0D47A1">ALTERAR
                                                STATUS DA PRODUÇÃO PARA :</small>
                                        </v-row>
                                        <v-row class="pr-0 pl-1 pt-0 pb-1"
                                            v-if="(appApontamento.resourceApontamento.tipo == 'Parada') || (!appApontamento.resourceApontamento.name)">
                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#43A047" height="45"
                                                    stacked size="small" @click="click_produzindo">
                                                    PRODUZINDO
                                                </v-btn>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#1E88E5" height="45"
                                                    stacked size="small" @click="click_manutencao">
                                                    MANUTENÇÃO
                                                </v-btn>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#F9A825" height="45"
                                                    stacked size="small" @click="click_setup">
                                                    SETUP
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <v-row v-else class="pr-0 pl-1 pt-0 pb-1">
                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#D50000" height="45"
                                                    stacked size="small" @click="motivo_de_parada">
                                                    PARADO
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#1E88E5" height="45"
                                                    stacked size="small" @click="click_manutencao">
                                                    MANUTENÇÃO
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn id="btn-style" style="width: 100%;" color="#F9A825" height="45"
                                                    stacked size="small" @click="click_setup">
                                                    SETUP
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="infoprod mt-2 m-2">
                                        <v-row justify="center" class="mt-2">
                                            <small style="font-weight: bold;font-size: 20px;color:#0D47A1;">INFORMAÇÕES
                                                DA PRODUÇÃO:</small>
                                        </v-row>
                                        <v-row class="pr-0 pl-1 pt-0 pb-1">
                                            <v-col cols="6" class="m-0 p-1">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact"
                                                        v-model="appApontamento.resourceApontamento.nome_operador"
                                                        label="OPERADOR" readonly
                                                        style="font-weight: bold;width: 90%;" id="input_user_shop"
                                                        placeholder="Não Informado" persistent-placeholder
                                                        append-inner-icon="mdi-close"
                                                        @click:append-inner="ClearFuncionario"></v-text-field>
                                                    <v-btn size="40" @click="ShowFuncionarios" class="mb-5 ml-2"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" class="m-0 p-1">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact"
                                                        v-model="appApontamento.resourceApontamento.observacao"
                                                        label="OBSERVAÇÃO" readonly
                                                        style="font-weight: bold;width: 80%;" id="input_user_shop"
                                                        placeholder="Não Informado" persistent-placeholder
                                                        append-inner-icon="mdi-close"
                                                        @click:append-inner="ClearObs"></v-text-field>
                                                    <v-btn size="40" class="mb-5 ml-2" @click="ShowObs"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-file-edit-outline"></v-btn>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" class="m-0 p-1" v-if="appApontamento.mostrarMotivoParada">
                                                <div style="display: flex; align-items: center;" v-if="appApontamento.resourceApontamento.tipo == 'Parada' && !appApontamento.isSetupClicked">
                                                <v-text-field density="compact" bg-color="#D50000"
                                                    :label="(appApontamento.resourceApontamento.tipo == 'Parada') && (appApontamento.resourceApontamento.desc_motivo_parada == null) ? 'INFORMAR O MOTIVO DE PARADA' : 'MOTIVO PARADA'"
                                                    readonly v-model="appApontamento.resourceApontamento.desc_motivo_parada" style="font-weight: bold;width: 80%;"
                                                    placeholder="Não Informado" persistent-placeholder append-inner-icon="mdi-close"
                                                    @click:append-inner="ClearMotivoParada"></v-text-field>
                                                <v-btn v-if="appApontamento.resourceApontamento.motivo_de_parada_manutencao_setup !== 'Setup'"
                                                    size="40" class="mb-5 ml-2" @click="ShowMp" style="width: 10%;font-size: 25px; min-width: 40px;"
                                                    color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" class="m-0 p-1"
                                                v-if="appApontamento.resourceApontamento.tipo == 'Parada' && !appApontamento.isSetupClicked || appApontamento.mostrarMotivoParada">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact" bg-color="#D50000"
                                                        :label="(appApontamento.resourceApontamento.tipo == 'Parada') && (appApontamento.resourceApontamento.desc_motivo_parada == null) ? 'INFORMAR O MOTIVO DE PARADA' : 'MOTIVO PARADA'"
                                                        readonly
                                                        v-model="appApontamento.resourceApontamento.desc_motivo_parada"
                                                        style="font-weight: bold;width: 80%;"
                                                        placeholder="Não Informado" persistent-placeholder
                                                        append-inner-icon="mdi-close"
                                                        @click:append-inner="ClearMotivoParada"></v-text-field> 
                                                    <v-btn
                                                        v-if="appApontamento.resourceApontamento.motivo_de_parada_manutencao_setup !== 'Setup'"
                                                        size="40" class="mb-5 ml-2" @click="ShowMp"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" class="m-0 p-1">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact"
                                                        v-model="appApontamento.resourceApontamento.ordem_de_producao"
                                                        label="ORDEM DE PRODUÇÃO" readonly
                                                        style="font-weight: bold;width: 80%;" id="input_user_shop"
                                                        placeholder="Não Informado" persistent-placeholder
                                                        append-inner-icon="mdi-close"
                                                        @click:append-inner="ClearOP"></v-text-field>
                                                    <v-btn size="40" class="mb-5 ml-2" @click="ShowOP"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import eventosApontamento from '../eventosApontamento.vue';

    import { colorStatusParada } from '../../utils/funcoes';

    import { useAppStore } from '../../../stores/app';
    import { useAppApontamento } from '../../../stores/appApontamento';
    import { useAppFuncionarios } from '../../../stores/appFuncionarios';
    import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';
    import { useAppMotivoDeParada } from '../../../stores/appMotivosdeParada';

    export default {
        name: "apontamento",
        data() { return { appApontamento: { resourceApontamento: { tipo: '', desc_motivo_parada: null, }, mostrarMotivoParada: true, } }; },
        components: { eventosApontamento },

        computed: {
            appApontamento() { return useAppApontamento(); },

            colorStatusApontamento() {
                switch (useAppApontamento().resourceApontamento.tipo) {
                    case 'Produção': return '#43A047';
                    case 'Parada': return colorStatusParada(useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup);
                    default: return 'secondary';
                }
            },
        },

        methods: {
            async click_manutencao() {
                const message = { message: 'MANUTENÇÃO INICIADA', color: 'secondary', showMessage: true }
                useAppApontamento().resourceApontamento.tipo = 'Parada';
                useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup = 'Manutenção';
                const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup)
                const json_update = {
                    tipo: useAppApontamento().resourceApontamento.tipo,
                    motivo_de_parada: mp_setup.name,
                    desc_motivo_parada: mp_setup.descricao
                }

                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)
                useAppStore().message = message
            },

            async click_setup() {
                this.appApontamento.isSetupClicked = true;
                this.appApontamento.resourceApontamento.tipo = 'Setup';
                this.appApontamento.mostrarMotivoParada = false;
                const message = { message: 'SETUP INICIADO', color: 'secondary', showMessage: true }
                useAppApontamento().resourceApontamento.tipo = 'Parada';
                useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup = 'Setup';
                const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup)

                const json_update = {
                    tipo: useAppApontamento().resourceApontamento.tipo,
                    motivo_de_parada: mp_setup.name,
                    desc_motivo_parada: mp_setup.descricao
                }

                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)
                useAppStore().message = message
            },

            ShowFuncionarios() { useAppFuncionarios().showModal = true; },

            ShowOP() {
                useAppStore().view_apontamento_historico = true;
                useAppOrdemProducao().showModal = true;
            },

            ShowObs() {
                useAppStore().view_apontamento_historico = true;
                useAppApontamento().showModalObs = true;
                useAppApontamento().observacao_modal = useAppApontamento().resourceApontamento.observacao;
            },

            async ShowMp() {
                useAppStore().view_apontamento_historico = true;
                useAppMotivoDeParada().apontamento_name = useAppApontamento().resourceApontamento.name
                await useAppMotivoDeParada().listar_motivos_de_parada();
                useAppStore().showModalMotivoDeParada = true;
            },

            click_produzindo() {
                const status = 'Produção'
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { tipo: status })
                this.set_apontamento_tipo(status)
                const message = { message: 'PRODUÇÃO INICIADA', color: 'secondary', showMessage: true }
                useAppStore().message = message
            },

            motivo_de_parada() {
                const status = 'Parada'
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { tipo: status })
                this.set_apontamento_tipo(status)
                const message = { message: 'PRODUÇÃO PARADA', color: 'secondary', showMessage: true }
                useAppStore().message = message
            },
            
            set_apontamento_tipo(status) { useAppApontamento().resourceApontamento.tipo = status },

            ClearFuncionario() {
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { operador: null })
                useAppApontamento().resourceApontamento.nome_operador = null
            },

            ClearObs() {
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { observacao: null })
                useAppApontamento().resourceApontamento.observacao = null
            },

            ClearOP() {
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { ordem_de_producao: null })
                useAppApontamento().resourceApontamento.ordem_de_producao = null
            },

            ClearMotivoParada() {
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, { motivo_de_parada: null })
                useAppApontamento().resourceApontamento.desc_motivo_parada = null
            },
        }
    };
</script>

<style></style>