<template>
    <v-toolbar :color="colorStatusApontamento" class="toolbar-realtime" height="40" id="v-toolbar_production">
        <v-row justify="space-between" class="pl-0 pr-0">
            <v-col cols="1" class="pl-0 pr-0 pt-0 pb-0">
                <v-card v-if="verify_status_sensor" :color="status_color" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon size="35">mdi-lan-connect</v-icon>
                </v-card>

                <v-card v-else :color="'#78909C'" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon size="35">mdi-lan-disconnect</v-icon>
                </v-card>
            </v-col>
            <v-col cols="11" class="pl-0 pr-0">
                <small v-if="appStore.apontamento_atual.name" class="ml-2" style="font-weight: bold;font-size: 16px;">
                    TEMPO REAL DA MÁQUINA : {{ tempExecucao }} | ID : {{ appStore.maquina_sel.id }} |
                </small>

                <v-btn v-if="appStore.maquina_sel && appStore.maquina_sel.nome" id="btn-maquina" variant="tonal"
                    class="ml-2" @click="ShowMaquinas"
                    :color="appStore.apontamento_atual.tipo === 'Produção' ? '#1B5E20' : '#E53935'"
                    style="font-weight: bold; font-size: 16px; color: white;" height="30">
                    {{ appStore.maquina_sel.nome }}
                </v-btn>

                <small class="ml-2" style="font-weight: bold;font-size: 16px;"> | {{ appStore.maquina_sel.leitor
                    }}</small>

                <v-btn class="ml-4" v-if="appStore.apontamento_atual.op"
                    style="font-weight: bold;font-size: 16px;color:white" @click="ShowDetalheOS" variant="tonal"
                    height="30" append-icon="mdi-folder-open">
                    DESENHOS
                </v-btn>
            </v-col>
        </v-row>
    </v-toolbar>
</template>

<script>
import { useAppStore } from '../../stores/app';
import { useAppMaquinas } from '../../stores/appMaquinas';
import { useAppOrdemProducao } from "../../stores/appOrdemProducao";
import { CalculaTempoMaquina, colorStatusParada } from '../utils/funcoes';

export default {
    name: "headerApontamento",
    components: {},
    data() {
        return {
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            time: 0,
            filter_maquinas: '',
        };
    },
    actions: { async selecionar_maquina(maquina) { this.maquina_sel = maquina; } },
    computed: {
        resourceMaquinas() {
            const filter = this.filter_maquinas.toUpperCase();
            return useAppMaquinas().resourceMaquinas.filter(maquina =>
                maquina.nome.toUpperCase().includes(filter)
            );
        },
        appStore() { return useAppStore(); },
        verify_status_sensor() {
            const status = useAppStore().apontamento_atual.state_sensor;
            return status === 'ACTIVE' || status === 'IDLE';
        },
        status_color() {
            const status = this.appStore.maquina_sel.state_sensor;
            if (status === 'ACTIVE') {
                return '#43A047'; // Verde 
            } else if (status === 'IDLE') {
                return '#D50000'; // Vermelho 
            } else {
                return '#78909C'; // Cinza 
            }
        },
        isMachineSelected() { return (useAppStore().maquina_sel.id != null) },

        colorStatusApontamento() {
            try {
                if (useAppStore().apontamento_atual.tipo) {
                    switch (useAppStore().apontamento_atual.tipo) {
                        case 'Parada':
                            return colorStatusParada(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup);
                        case 'Produção':
                            return '#43A047';
                        default:
                            return 'primary';
                    }
                } else 
                { 
                    return 'primary'; 
                }
            } catch (e) { throw 'Error color status apontamento' + e }
        },
    },
    watch: {
        isMachineSelected(newVal) {
            if (!newVal) {
                this.isMenuOpen = true;
            }
            else {
                this.inicia_count_machine()
            }
        }
    },
    mutations: { selecionar_maquina(maquina) { this.maquina_sel = maquina; }, },

    methods: {
        inicia_count_machine() {
            clearInterval(this.time);
            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },

        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(useAppStore().apontamento_atual.dt_inicio)
            ).tempo
        },
        ShowMaquinas() {
            const appMaquinas = useAppMaquinas();
            appMaquinas.showModal = true;
        },
        async ShowDetalheOS() {
            const appOrdemProducao = useAppOrdemProducao();
            const appStore = useAppStore();
            await appOrdemProducao.listar_files_ordem_producao(appStore.apontamento_atual.op);
            appStore.showModalObsDetalhes = true;
        },
        async SelecionarMaquina(maquina) {
            const appStore = useAppStore();
            await appStore.selecionar_maquina(maquina);
            await this.atualizarMotivosDeParada();
            this.isMenuOpen = false;
        },
        async atualizarMotivosDeParada() {
            const appMotivoDeParada = useAppMotivoDeParada();
            const appStore = useAppStore();
            appMotivoDeParada.apontamento_name = appStore.apontamento_atual.name;
            await appMotivoDeParada.listar_motivos_de_parada();
            appStore.showModalMotivoDeParada = true;
        },
    },

    mounted() { useAppMaquinas().showModal = true; },
}
</script>

<style></style>