<template>
    <v-dialog v-model="appOrdemProducao.showModal" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="appOrdemProducao.showModal = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title>ORDENS DE PRODUÇÃO</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-text-field density="compact" v-model="filter_op" label="FILTRAR ORDEM DE PRODUÇÃO" id="filter_op"
                        class="mt-2 mb-0 pl-1 pr-1 pt-0 pb-0" style="width: 100%;font-weight: bold;" variant="solo"
                        bg-color="secondary" placeholder="FILTRAR ORDEM DE PRODUÇÃO"></v-text-field>

                </v-row>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0 mt-1 mb-0 pl-0 pr-0">
                        <ejs-grid :allowPaging='true' :dataSource="resourceOrdemDeProdocuao" id="grid_op"
                            :recordClick="rowSelectedOp" width="100%" :allowSorting='true' :allowFiltering='true'>
                            <e-columns>
                                <e-column headerText='ORDEM DE PRODUÇÃO' field="name" width='100'
                                    textAlign="Center"></e-column>

                                <e-column headerText='CLIENTE' field="desc_cliente" width='100'
                                    textAlign="Left"></e-column>

                                <e-column headerText='DESCRIÇÃO' field="descricao" width='100'
                                    textAlign="Left"></e-column>


                                <e-column headerText='CRITICIDADE' :template="'columnTemplate'" field="criticidade"
                                    width='100' textAlign="Center"></e-column>

                                <e-column headerText='DATA DE ENTREGA' :template="'templateDateEntrega'"
                                    field="dt_entrega" width='100' textAlign="Center"></e-column>

                                <e-column headerText='QUANTIDADE' field="quantidade" width='100'
                                    textAlign="Center"></e-column>
                            </e-columns>
                            <template v-slot:columnTemplate="{ data }">
                                <div class="criticidade">
                                    <v-chip color="#C62828" style="font-weight: bold;"
                                        v-if="data.criticidade == 'Urgente'">
                                        {{ data.criticidade }}
                                    </v-chip>
                                    <v-chip color="#0091EA" style="font-weight: bold;"
                                        v-else="data.criticidade == 'Normal'">
                                        {{ data.criticidade }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:templateDateEntrega="{ data }">
                                <div class="dt_entrega">
                                    <span>{{ formatDate(data.dt_entrega) }}</span>
                                </div>
                            </template>
                        </ejs-grid>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';

import { useAppStore } from '../../../stores/app';

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Sort } from '@syncfusion/ej2-vue-grids';
import { formatDate } from '../../utils/funcoes';
import { useAppApontamento } from '../../../stores/appApontamento';

export default {
    name: "ordemProducao",
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective
    },
    provide: {
        grid: [Page, Edit, Toolbar, Sort]
    },
    computed: {
        appOrdemProducao() {
            return useAppOrdemProducao();
        },
        appStore() {
            return useAppStore();
        },
        appApontamento() {
            return useAppApontamento()
        },
        resourceOrdemDeProdocuao() {
            const filter = this.filter_op.toUpperCase();

            return useAppOrdemProducao().resourceOPs.filter(op =>
                (op.name && op.name.toUpperCase().includes(filter)) ||
                (op.desc_cliente && op.desc_cliente.toUpperCase().includes(filter)) ||
                (op.descricao && op.descricao.toUpperCase().includes(filter)) ||
                (op.criticidade && op.criticidade.toUpperCase().includes(filter)) ||
                (op.dt_entrega && formatDate(op.dt_entrega).toUpperCase().includes(filter)) ||
                (op.quantidade && op.quantidade.toString().toUpperCase().includes(filter))
            );
        }
    },
    data() {
        return {
            filter_op: ''
        }
    },
    methods: {

        rowSelectedOp(args) {
            const json_update = {
                ordem_de_producao: args.rowData.name
            }
            if (!this.appStore.view_apontamento_historico) {
                useAppStore().selecionar_op(args.rowData);
                this.appApontamento.update_apontamento(this.appStore.apontamento_atual.name, json_update)
            } else {
                this.appApontamento.selecionar_op(args.rowData);
                this.appApontamento.update_apontamento(this.appApontamento.resourceApontamento.name, json_update)
            };

            useAppOrdemProducao().showModal = false;

        },
        formatDate(data) {
            return formatDate(data);
        }
    },
    mounted() {
        useAppOrdemProducao().listar_ops()
    }
}

</script>