export function formatDate(input: string): string {
  try {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) { throw new Error(error); }
}

export function CalculaTempoMaquina(DataRequisicao: Date) {
  const GetdataAtual = new Date();
  const diffInSeconds = (GetdataAtual.getTime() - DataRequisicao.getTime()) / 1000;

  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = Math.floor(diffInSeconds % 60);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return { tempo: formattedTime };
}

export function formatDateSearch(data: string): string {
  try {
    const date = new Date(data);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`; 
  } catch (error) { throw new Error(error); }
}

export function colorStatusParada(tp_parada: string) {
  switch(tp_parada) {
    case 'Manutenção': return '#1E88E5';
    case 'Setup': return '#F9A825';
    default: return '#D50000';
  }
}
